<template>
  <div class="card-wrapper" @click="goToCourse">
    <div class="thumbnail-wrapper">
      <img :src="course.thumbnail"/>
      <div class="timer" v-if="course.expiration_date">
        <v-academy-timer :expirationDate="course.expiration_date" />
      </div>
    </div>
    <div class="card-content">
      <div class="card-title">{{ course.name }}</div>
      <div class="card-subtitle">{{ course.specialty }}</div>
    </div>
  </div>
</template>
<script>
import AcademyTimer from '@/components/Academy/AcademyTimer';

export default {
  props: {
    course: Object,
  },
  components: {
    'v-academy-timer': AcademyTimer,
  },
  methods: {
    goToCourse() {
      this.$router.push(`/academy/curso/${this.course.id}`)
    },
  }
}
</script>
<style lang="scss" scoped>
  .card-wrapper {
    width: 300px;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    .thumbnail-wrapper {
      position: relative;
      padding: 16px;
      height: 200px;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }

      .timer {
        bottom: 15px;
        right: 26px;
        position: absolute;
        display: flex;
        justify-content: center;
      }
    }



    .card-content {
      padding: 0 16px 16px 16px;
      .card-title {
        font-weight: 400;
        font-size: 18px;
        color: var(--dark-blue);
        margin-bottom: 0px;
      }
      .card-subtitle {
        font-weight: 600;
        font-size: 12px;
        margin-top: 0;
        color: var(--active-type);
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: var(--neutral-200);
        margin: 10px 0;
      }
      .time-description {
        font-weight: 600;
        font-size: 13px;
        color: var(--active-type);
        margin-bottom: 0.75rem;
      }
    }
  }
</style>
